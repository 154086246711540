import React from 'react'
import Input from '../../app/components/LocalElements/Input/InputMUI'
import { Button } from '@mui/material'
import ErrorBoundaryHOCComponent from '../../app/hocs/ErrorBoundary.HOC'
class ErrorBoundaryClassComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasError: false,
      error: '',
      errorInfo: '',
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error: `${error.name}: ${error.message}` })
    this.setState({ errorInfo: JSON.stringify(errorInfo) })
    this.setState({ hasError: !this.state.hasError })

    false && console.log(errorInfo)
  }

  returnErrorInfo(errorInfo) {
    return errorInfo
  }

  reloadApp = () => {
    this.props.reloadApp()
  }

  render() {
    const { error, errorInfo } = this.state

    if (!!error) {
      return (
        <main className='main'>
          <div className='edit-password-box'>
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: '25px',
              }}
            >
              <h2 style={{ width: '100%', textAlign: 'center' }}>Произошел сбой приложения</h2>
              <div className='close-icon' onClick={() => {}}>
                {/* <i className='fas fa-times user-icon-exit' /> */}
              </div>
            </div>

            <span style={{ marginBottom: '13px' }}>{error ? error : 'Наименование ошибки'}</span>
            <span
              style={{
                marginBottom: '20px',
                width: '100%',
                lineHeight: '24px',
                textAlign: 'center',
              }}
            >
              {this.returnErrorInfo(errorInfo)
                ? this.returnErrorInfo(errorInfo)
                : 'Более подробное описание ошибки'}
            </span>

            <div style={{ width: '100%', marginBottom: '20px' }}>
              <Input
                label={'Сообщите о проблеме разработчикам'}
                isDisabled={true}
                type={'text'}
                placeholder={'Опишите в каких обстоятельствах произошла ошибка'}
                value={''}
                actions={{
                  change: () => {},
                }}
              />
            </div>

            <div style={{ marginTop: '10px', width: '100%' }}>
              <Button
                variant='contained'
                disabled={true}
                onClick={() => {}}
                sx={{ mt: 1, mr: 1 }}
                style={{
                  textTransform: 'none',
                  fontSize: '15px',
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  padding: '11px 20px 11px',
                  margin: 0,
                  borderRadius: '12px',
                  width: '100%',
                }}
              >
                Оставить сообщение
              </Button>
            </div>
            <span
              style={{
                marginBottom: '13px',
                marginTop: '20px',
                letterSpacing: '1px',
                color: 'gray',
              }}
            >
              или
            </span>
            <div style={{ marginTop: '10px', width: '100%' }}>
              <Button
                variant='outlined'
                onClick={this.reloadApp}
                sx={{ mt: 1, mr: 1 }}
                style={{
                  textTransform: 'none',
                  fontSize: '15px',
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  padding: '11px 20px 11px',
                  margin: 0,
                  borderRadius: '12px',
                  width: '100%',
                }}
              >
                Перезагрузить приложение
              </Button>
            </div>
          </div>
        </main>
      )
    } else {
      return <React.Fragment>{this.props.children}</React.Fragment>
    }
  }
}

const ErrorBoundary = ErrorBoundaryHOCComponent(ErrorBoundaryClassComponent)

export default ErrorBoundary
