import { RadioGroup, FormControlLabel, Radio } from '@mui/material'

const Approved = ({
  changeApproved,
  approved,
  labelTrue,
  labelFalse,
  valueTrue,
  valueFalse,
  disabled,
}) => {
  return (
    <RadioGroup
      row
      aria-labelledby='demo-row-radio-buttons-group-label'
      name='row-radio-buttons-group'
      value={approved}
      onChange={changeApproved}
    >
      <FormControlLabel
        value={valueTrue}
        control={<Radio />}
        label={labelTrue}
        disabled={disabled}
        sx={{
          '& .MuiTypography-root': {
            fontFamily: "'Wix Madefor Display', sans-serif !important",
            fontSize: '15px !important',
            fontWeight: 400,
            color: '#132532',
          },
        }}
      />
      <FormControlLabel
        value={valueFalse}
        control={<Radio />}
        label={labelFalse}
        disabled={disabled}
        sx={{
          '& .MuiTypography-root': {
            fontFamily: "'Wix Madefor Display', sans-serif !important",
            fontSize: '15px !important',
            fontWeight: 400,
            color: '#132532',
          },
        }}
      />
    </RadioGroup>
  )
}

export default Approved
