import axios from 'axios'
import Cookies from 'js-cookie'
import D from '../../store/localStorage/dispatcher'

const createAxiosInstance = () => {
  const headers = {
    Authorization: `Bearer ${Cookies.get('accessToken')}`,
    Accept: 'application/json, text/plain, */*',
    'Content-Type': 'application/json',
  }

  const instance = axios.create({ headers })

  instance.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 401) {
        Cookies.remove('accessToken')

        D.cls()

        // ----------------------------------------------------------------
        // window.location.href = "/"
        // ----------------------------------------------------------------
      }
      return Promise.reject(error)
    },
  )

  return instance
}

export default createAxiosInstance()
